<template>
        <el-dialog title="Slideshows" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item prop="TitleRo" label='Title Ro' >
                    <el-input  class='full-width' v-model='selectedObject.TitleRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="TitleEn" label='Title De' >
                    <el-input  class='full-width' v-model='selectedObject.TitleEn' />
                </el-form-item>
            </el-col>
            <el-col :md='12'>
                <el-form-item prop="DescriptionRo" label='Description Ro' >
                    <el-input type="textarea" class='full-width' v-model='selectedObject.DescriptionRo' />
                </el-form-item>
            </el-col>
            <el-col :md='12'>
                <el-form-item prop="DescriptionEn" label='Description De' >
                    <el-input type="textarea" class='full-width' v-model='selectedObject.DescriptionEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="LinkRo" label='Link Ro' >
                    <el-input  class='full-width' v-model='selectedObject.LinkRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="LinkEn" label='Link De' >
                    <el-input  class='full-width' v-model='selectedObject.LinkEn' />
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item prop="uploadPreview_Image" label='Image' >
                <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Slideshows/upload_Image'"
                        :show-file-list='false'
                        :on-success='handleUploadSuccess_Image'>
                            <i class='el-icon-plus my-uploader-icon'></i>
                        </el-upload>       
                        <img class="imagePreview" v-if='selectedObject.uploadPreview_Image' :src='selectedObject.uploadPreview_Image' > 
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="Pozitie" label='Pozitie' >
                    <el-input-number class='full-width' v-model='selectedObject.Pozitie' :precision='0' :max='999999'></el-input-number>
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Slideshows_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    TitleRo: '' , TitleEn: '' , DescriptionRo: '' , DescriptionEn: '' , LinkRo: '' , LinkEn: '' , Image: '' , uploadPreview_Image : '' , Pozitie: '' ,                 },
                Info:{
                                    },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    TitleRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TitleEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DescriptionRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DescriptionEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    LinkRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    LinkEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Image: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Pozitie: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.selectedObject = {};
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("slideshows/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("slideshows/get_info_for_dialog" );
                            },
             handleUploadSuccess_Image(response){
                    this.selectedObject.Image = response.FileName;
                    this.selectedObject.uploadPreview_Image  = response.Url;
                },
                    save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("slideshows/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

    .imagePreview {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

</style>